body {
  overflow: hidden;
}

*:focus-visible {
  outline: unset;
}

.full-page {
  margin-top: -64px;
  margin-bottom: -16px;
  margin-left: -24px;
  margin-right: -24px;
}

/* width */
body ::-webkit-scrollbar {
  width: 16px;
  height: 8px;
}

/* Track */
body ::-webkit-scrollbar-track {
  background: #555;
  border-radius: 8px;
  margin: 8px;
}

/* Handle */
body ::-webkit-scrollbar-thumb {
  background: #4D9D80;
  border-radius: 8px;
}

/* Handle on hover */
body ::-webkit-scrollbar-thumb:hover {
  background: #4D9D80;
}
